jQuery(document).ready(function( $ ) {
    $('.variations_form').each(function(){
        $(this).wc_variation_form();
    });
	
    var updatePrice = function() {
        var variation = $('.single_variation .woocommerce-variation-price').html();
        if (variation) {
            $('.entry-summary p.price').html(variation);
            if(window.user_is_loggedin) {
                $('.entry-summary p.price span.price').show();
            }
        } else {
            setTimeout(updatePrice, 1000);
        }
    };
    $('.variations_form').on('woocommerce_variation_has_changed', updatePrice);

    var product_prices = {};
    $('.product-price-simple').each(function() {
        var simple_price_element = $(this);
        var product_id = simple_price_element.data('productId');
        if(typeof product_prices[product_id] === 'undefined') {
            product_prices[product_id] = [];
        }
        product_prices[product_id].push(simple_price_element);
    });

    //loop product_prices und hole per ajax die Preise
    $.each(product_prices, function(product_id, price_elements) {
        $.ajax({
            url: child_theme.ajaxurl,
            type: 'post',
            data: {
                action: 'get_product_price',
                product_id: product_id
            },
            success: function(response) {
                if(!response.success) {
                    console.error('Error: ', response.data);
                    return;
                }
                $.each(price_elements, function() {
                    $(this).html(response.data);
                });
            }
        });
    });
});



